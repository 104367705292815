import React from 'react'
import { IImageGridMasonryProps } from './types'
import {
  ImageGridMasonryWrapper,
  ImageGridItem,
  ImageGridItemTitle,
  ImageGridItemLink,
  ImageGridItemContainer,
} from './styles'
import ResposiveImage from '~/componentsV2/ResposiveImage'
import { PageContainer } from '../../components/PageContainer'
import TextBox from '../../components/TextBox'
import { ImageWithLink } from '../../components/ImageLink'

export const ImageGridMasonrySection: React.FC<IImageGridMasonryProps> = ({ data }) => {
  const { columns, images, container, background, textPosition, textBox } = data

  // if (images.length === 5) {
  //   console.log('# images', images)
  // }

  return (
    <PageContainer container={container} background={background}>
      <ImageGridItemContainer>
        {textPosition && textPosition === 'top' && <TextBox {...textBox} />}
        <ImageGridMasonryWrapper $columns={columns}>
          {images.map(image => (
            <ImageGridItem key={image.id}>
              <ImageWithLink
                to={image.link}
                desktop={image.desktopImage}
                tablet={image.tabletImage}
                mobile={image.mobileImage}
              />
            </ImageGridItem>
          ))}
        </ImageGridMasonryWrapper>
        {textPosition && textPosition === 'bottom' && <TextBox {...textBox} />}
      </ImageGridItemContainer>
    </PageContainer>
  )
}
