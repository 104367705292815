import styled, { css } from 'styled-components'

import { IPageBackground, IPageImage } from '../../types'
import { IStyledPageContainerProps } from './types'

export const StyledPageContainer = styled.div<IStyledPageContainerProps>`
  ${({ $size, $disabled }) =>
    $size
      ? $size === 'full'
        ? css`
            width: 100%;
          `
        : css`
            width: 100%;
            max-width: ${$size}px;
          `
      : css`
          width: 100%;
          max-width: 1440px;
        `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      display: none;
    `}

  margin: 0 auto;
  ${({ $py }) =>
    $py &&
    css`
      padding-top: ${$py}px;
      padding-bottom: ${$py}px;
    `}
  ${({ $px }) =>
    $px &&
    css`
      padding-left: ${$px}px;
      padding-right: ${$px}px;
    `}
`

export const PageBackground = styled.div<{
  $backgroundImage?: IPageBackground
  $backgroundColor?: string
}>`
  ${({ $backgroundImage }) =>
    $backgroundImage?.desktopImage &&
    css`
      background-image: url(${$backgroundImage.desktopImage.url});
      background-size: cover;
      background-position: center;

      ${({ theme }) =>
        $backgroundImage?.mobileImage &&
        '@media ' +
          theme.mediaQueries.mobile +
          ' { background-image: url(' +
          $backgroundImage.mobileImage.url +
          '); }'}

      ${({ theme }) =>
        $backgroundImage?.tabletImage &&
        '@media ' +
          theme.mediaQueries.tablet +
          ' { background-image: url(' +
          $backgroundImage.tabletImage.url +
          '); }'}
    `}
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `}
`
