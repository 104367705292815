import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IPageBuilderApiData, IPageBuilderData, IPageBuilderSection } from './types'
import { PageBuilderContext } from './context'
import Layout, { LayoutProps } from '~/components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import { QueryResult } from '~/components/seo/types'
import { parseCmsData } from './utils'
import { cmsEnv } from '~/common/cms'
import { PageContent, PageWrapper } from './styles'
import { PageBuilderSection } from './components/PageBuilderSection'
import homeSections, { SectionKeys } from './sections'
import HeroSlider from './components/HeroSlider'
import { useIdentifierModal } from '~/context/identifier-modal'
import { template } from 'lodash'

interface PageBuilderProviderProps {
  pageId?: string
  path: string
  preloadedData?: IPageBuilderData
  layoutProps?: Partial<LayoutProps>
  noIndex?: boolean
}

// eslint-disable-next-line @typescript-eslint/promise-function-async, @typescript-eslint/no-explicit-any
export const loadData = async (
  { path, pageId }: { path: string; pageId?: string },
  isDraft: boolean
): Promise<IPageBuilderApiData> => {
  const url = pageId ? `${cmsEnv.endpoint}/${path}/${pageId}` : `${cmsEnv.endpoint}/${path}`
  const res = await fetch(`${url}?populate=deep${isDraft ? '&draft=true' : ''}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${cmsEnv.token}`,
    },
  })
  return (await res.json()).data
}

export const PageBuilder: React.FC<PageBuilderProviderProps> = ({
  layoutProps,
  preloadedData,
  pageId,
  path,
  noIndex,
}) => {
  const [data, setData] = useState<IPageBuilderData | undefined>(() => {
    return preloadedData ? parseCmsData(preloadedData) : undefined
  })
  const { customerSegment } = useIdentifierModal()

  const parseSegmentContent = useCallback(
    (content: IPageBuilderSection[]) => {
      if (!content) {
        return []
      }

      return (
        content.filter(
          item =>
            !item.visibility || item.visibility === customerSegment || item.visibility === 'all'
        ) || []
      )
    },
    [customerSegment]
  )

  const { hero, sections, banner } = useMemo(() => {
    if (!data) {
      return { hero: [], sections: [], banner: [] }
    }

    const { hero: _hero, sections: _sections, banner: _banner } = data
    const hero = parseSegmentContent(_hero)
    const sections = parseSegmentContent(_sections)
    const banner = parseSegmentContent(_banner)

    return { hero, sections, banner }
  }, [data])

  useEffect(() => {
    // query selector draft
    const draft = window.location.search.includes('draft=true')
    const regex = /\/preview\/(\d+)/
    const urlId = window.location.pathname.match(regex)?.[1]

    if (!preloadedData || draft) {
      loadData(
        {
          path,
          pageId: urlId || pageId,
        },
        draft
      ).then(data => {
        setData(parseCmsData<IPageBuilderData>(data))
      })
    }
  }, [preloadedData, pageId])

  const query = useStaticQuery<QueryResult>(graphql`
    query {
      site {
        siteMetadata {
          defaultImage
          defaultDescription: description
        }
      }
    }
  `)

  const image = query.site.siteMetadata.defaultImage
  const description = query.site.siteMetadata.defaultDescription
  const { gap } = data?.styles || {}

  if (!data) {
    return null
  }

  const Banner = homeSections.banner
  const bgColor = data?.styles?.backgroundColor || '#fff'

  // console.log('#', data)

  return (
    <PageWrapper $bgColor={bgColor}>
      <PageBuilderContext.Provider value={{ pageId: path, ...data }}>
        <Layout
          headerVariant="default"
          seoTitle="Ben Soleimani | Rugs & Furniture | Iconic Design | Bespoke Quality"
          description={description}
          image={image}
          topBanner={true}
          stickyMenu={true}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'stretch',
            gap: [20, 35],
          }}
          noIndex={noIndex}
          template={data?.template}
          {...layoutProps}
        >
          {banner?.length > 0 && banner.map(item => <Banner data={item as any} />)}
          <PageContent $gap={gap}>
            {!!hero?.length && <HeroSlider items={hero} />}
            {sections.map(section => {
              const type = section.__component.split('.').pop() as SectionKeys
              const Section = homeSections[type]
              return (
                <PageBuilderSection overlay section={Section} data={section} key={section.id} />
              )
            })}
          </PageContent>
        </Layout>
      </PageBuilderContext.Provider>
    </PageWrapper>
  )
}

export default PageBuilder
