import styled from 'styled-components'

export const BannerWrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
  width: 100vw;
  min-height: 50px;
  background: var(--theme-ui-colors-bgDarkBlue);
  color: var(--theme-ui-colors-white);
  --text-color: var(--theme-ui-colors-white);
  padding: 10px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: absolute;
  z-index: 50;
  top: 0;

  * {
    color: #fff;
    margin-bottom: 0 !important;
  }
`
