/** @jsx jsx */
import { Button, jsx } from 'theme-ui'
import Chevron from '~/components/icons/chevron'

const SliderArrowCustom = ({
  position = 'left',
  sx = {},
  disabled = false,
  className = '',
  ...rest
}): JSX.Element => {
  return (
    <Button
      variant="noStyle"
      disabled={disabled}
      className={className}
      sx={{
        position: 'absolute',
        top: '50%',
        transform: `translateY(-50%)`,
        left: position === 'left' && 0,
        right: position === 'right' && 0,
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.4)',
        padding: 0,
        width: 55,
        height: 80,
        svg: {
          width: ['1rem', '1.3rem'],
        },
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...sx,
      }}
      {...rest}
    >
      <Chevron direction={position} width="5rem" height="5rem" />
    </Button>
  ) as JSX.Element
}

export default SliderArrowCustom
