import GridSection from './GridSection'
import HeroSection from './HeroSection'
import CardSection from './CardSection'
import TitleSection from './TitleSection'
import SliderSection from './SliderSection'
import BrandsSection from './BrandsSection'
import VideoSection from './VideoSection'
import BannerSection from './BannerSection'
import HeroImageSection from './HeroImage'
import TextSection from './TextSection'
import FlipBookSection from './FlipBookSection'
import { ImageGridSection, ImageGridMasonrySection } from './ImageGrid'
import CardGridSection from './CardGridSection'
import CollectionSlider from './CollectionSlider'
import SliderTabs from './SliderTabs'

const sections = {
  grid: GridSection,
  hero: HeroSection,
  card: CardSection,
  title: TitleSection,
  slider: SliderSection,
  brands: BrandsSection,
  video: VideoSection,
  banner: BannerSection,
  'image-hero': HeroImageSection,
  'text-section': TextSection,
  flipbook: FlipBookSection,
  'image-grid': ImageGridSection,
  'image-grid-masonory': ImageGridMasonrySection,
  'card-grid': CardGridSection,
  'collection-slider': CollectionSlider,
  'slider-tab': SliderTabs,
}

export default sections

export type SectionKeys = keyof typeof sections
