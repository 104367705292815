import styled, { css } from 'styled-components'
import { TGridColumns, TGridMasonryColumns } from '../../types'
import { Link } from 'gatsby'

const gridColumns = {
  'Cols 1 / 1 / 1': '1fr 1fr 1fr',
  'Cols 1 / 1': '1fr 1fr',
  'Cols 1 / 2': '1fr 2fr',
  'Cols 2 / 1': '2fr 1fr',
}

const gridStylesMasonry: Record<TGridMasonryColumns, number> = {
  'Cols 2': 2,
  'Cols 3': 3,
  'Cols 4': 4,
}

export const ImageGridItem = styled.div`
  width: 100%;
  position: relative;
  object-fit: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .text-box {
    width: 100%;
    margin-top: 50px;
  }

  &:hover {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
  }
`

export const ImageGridItemLink = styled(Link)`
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
  overflow: hidden;
`

export const ImageGridItemContainer = styled.div`
  margin: 0 auto;
  width: 100%;

  > .text-box {
    margin-top: 50px;
  }

  @media (min-width: 1440px) {
    max-width: 1440px;
    padding: 70px 80px;
  }
`

export const ImageGridWrapper = styled.div<{ $columns: TGridColumns }>`
  display: grid;
  gap: 8px;
  width: 100%;
  align-items: start;
  grid-template-columns: 100%;

  @media (min-width: 1440px) {
    grid-template-columns: ${({ $columns }) => gridColumns[$columns]};
  }
`

export const ImageGridMasonryWrapper = styled.div<{ $columns: TGridMasonryColumns }>`
  margin: 0 auto;
  display: grid;
  gap: 8px;
  width: 100%;
  grid-template-columns: repeat(${({ $columns }) => gridStylesMasonry[$columns]}, 1fr);

  padding: 0 !important;

  ${ImageGridItem} {
    aspect-ratio: unset;

    &:nth-child(5n + 1) {
      grid-row: span 2;
    }
    &:nth-child(5n + 2) {
      grid-row: span 3;
    }
    &:nth-child(5n + 3) {
      grid-row: span 1;
    }
    &:nth-child(5n + 4) {
      grid-row: span 2;
    }
    &:nth-child(5n + 5) {
      grid-row: span 1;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }

  @media (max-width: 767px) {
    max-height: 50vh;
    overflow: hidden;
  }
`

export const ImageGridItemTitle = styled(Link)`
  position: absolute;
  left: 0;
  width: 100%;
  text-decoration: none;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline;
  }

  h3 {
    color: #fff;
    text-align: center;
    font-family: Arial;
    font-size: 14.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.04px;
    letter-spacing: 6.026px;
    text-transform: uppercase;
  }
`
