import React from 'react'
import ResposiveImage, { IMedia } from '../../../ResposiveImage'
import Slide from '../../components/HeroSlider2/Slide'
import { ISlideProps } from '../../components/HeroSlider2/types'
import { TPageTextPosition } from '../../types'

export interface HeroImageSectionProps {
  data: Omit<ISlideProps, 'position'> & { position: TPageTextPosition }
}

const HeroImageSection: React.FC<HeroImageSectionProps> = props => {
  const { position, ...rest } = props.data

  const slide = { ...rest, textPosition: position?.position }
  return <Slide slide={slide} />
}

export default HeroImageSection
