import styled from 'styled-components'
import { CtaWrapper } from '../Cta/styles'

export const TextBoxWrapper = styled.div<{
  $color?: string
  $titleFontType?: string
  $titleAlignment?: string
  $descriptionFontType?: string
  $descriptionAlignment?: string
}>`
  display: flex;
  flex-direction: column;
  color: ${({ $color }) => $color || 'var(--text-color)'};

  --text-color: ${({ $color }) => $color || 'var(--text-color)'};

  p {
    margin: 0;
    margin-bottom: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
    font-family: var(--font-family-sans-serif);
    margin-bottom: 20px;
  }

  h4 {
    font-size: 18.6px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.53px;
    letter-spacing: 7.45px;
  }

  ${CtaWrapper} {
    margin-top: 40px;

    ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
      margin-top: 20px;
    }
  }

  img {
    margin: 0 auto;

    ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
      max-width: 50%;
    }
  }
`

const columnAlignment = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export const TextFormatWrapper = styled.div<{ $fontType?: string; $alignment?: string }>`
  display: contents;
  * {
    font-family: ${({ $fontType }) => $fontType || 'var(--font-family-serif)'} !important;
    text-align: ${({ $alignment }) => $alignment || 'center'} !important;
  }

  .cta {
    width: 100%;
    font-family: ${({ $fontType }) => $fontType || 'var(--font-family-sans-serif)'} !important;
    justify-content: ${({ $alignment }) => columnAlignment[$alignment || 'center']} !important;
    align-items: ${({ $alignment }) => columnAlignment[$alignment || 'center']} !important;
  }
`
