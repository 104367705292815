import styled from 'styled-components'

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: underline;
    color: var(--text-color);
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    text-underline-offset: 5px;
  }
`
