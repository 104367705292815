import React from 'react'
import { BlocksContent } from '@strapi/blocks-react-renderer'
import BlockTextRender from '../BlockTextRender'
import { TextBoxWrapper, TextFormatWrapper } from './styles'
import { TextBoxProps } from './types'
import { Cta } from '../Cta'

const TextBox: React.FC<TextBoxProps> = ({
  title,
  description,
  textColor,
  cta,
  titleFontType,
  titleAlignment,
  descriptionFontType,
  descriptionAlignment,
}) => {
  return (
    <TextBoxWrapper $color={textColor} className="text-box">
      {title ? (
        <TextFormatWrapper $fontType={titleFontType} $alignment={titleAlignment}>
          <BlockTextRender content={title} />
        </TextFormatWrapper>
      ) : null}

      {description ? (
        <TextFormatWrapper $fontType={descriptionFontType} $alignment={descriptionAlignment}>
          <BlockTextRender content={description} />
        </TextFormatWrapper>
      ) : null}

      {cta ? (
        <TextFormatWrapper $fontType={descriptionFontType} $alignment={descriptionAlignment}>
          <Cta {...cta} />
        </TextFormatWrapper>
      ) : null}
    </TextBoxWrapper>
  )
}

export default TextBox
