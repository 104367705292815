import React from 'react'
import { CtaWrapper } from './styles'
import { IPageCta } from '../../types'
import { Link } from 'gatsby'

export const Cta = (props: IPageCta) => {
  const { text, link } = props
  let linkProps: any = {
    to: '#',
  }

  if (link?.includes('event:')) {
    const event = link.split(':')[1]
    linkProps = {
      onClick: () => {
        document.dispatchEvent(new CustomEvent(event))
      },
      to: '#',
    }
  } else {
    linkProps = {
      to: link,
    }
  }

  return (
    <CtaWrapper className="cta">
      <Link {...linkProps}>{text}</Link>
    </CtaWrapper>
  )
}
