import styled from 'styled-components'
import { Link, GatsbyLinkProps } from 'gatsby'
import { TImageLinkFit } from './types'

interface StyledLinkProps extends GatsbyLinkProps<any> {
  $fit?: TImageLinkFit
}

export const ImageLink = styled(Link)<StyledLinkProps>`
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s ease;
  position: relative;
  cursor: default;

  &[href]:not([href='']) {
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }

  &:hover {
    &::after {
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: ${({ $fit }) => $fit || 'cover'};
  }
`
