import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  max-height: 100vh;
  width: 100%;
  padding: 100px 0;

  .card-section {
    width: 100%;
    margin: 0;
    max-height: 100vh;
    max-width: 100%;
    aspect-ratio: 16/ 6;
    min-height: 100%;

    &.video {
      aspect-ratio: 16/9;
    }

    ${({ theme }) => '@media ' + theme.mediaQueries.maxSm} {
      aspect-ratio: 9/10;
      min-height: 445px;
    }

    .card-content {
      /* max-width: 970px; */
      max-width: 1400px;
      justify-content: flex-start;
    }

    .card-background {
      img {
        object-position: top;
      }
    }

    .card-title {
      padding-top: 120px;
      ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
        padding-top: 90px;
        position: relative;
        z-index: 10;
      }
    }

    .card-description {
      bottom: 40px;
      z-index: 10;
    }
  }

  .slick-dots {
    display: none !important;
  }

  .slick-arrow {
    box-shadow: 0 0 5px 0 #868080;
  }

  .slick-prev {
    left: 40px;
  }

  .slick-next {
    right: 40px;
  }
`

export const Image = styled.img``

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TabNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
`

export const TabItem = styled.div`
  padding: 0 10px;
  color: #deddd5;
  font-family: var(--font-family-sans-serif);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 7.99px;
  letter-spacing: 0.976px;
  text-transform: uppercase;

  &[data-active='true'] {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
`

export const TabContent = styled.div`
  border: 1px solid rgba(222, 221, 213, 0.58);
  background-color: #f8f8f8;
  cursor: pointer;

  .product-item-content {
    display: none;
  }
`
