import React, { useMemo } from 'react'
import { PageContainer } from '../../components/PageContainer'
import { IPageContainer, IPageFlipbook, IPageFlipbookPage } from '../../types'
import Flipbook from '../../components/Flipbook'
import ResposiveImage from '~/componentsV2/ResposiveImage'
import { Link } from 'gatsby'
import { FlipBookSectionWrapper } from './styles'

export interface FlipBookSectionData {
  pages: IPageFlipbookPage[]
  container?: IPageContainer
}

export interface FlipBookSectionProps {
  data: FlipBookSectionData
}

type IPageFlipbookPageWithCover = IPageFlipbookPage & {
  pageNumber: number
  position?: 'left' | 'right' | 'full'
  isVisible?: boolean
}

const LinkMarker = () => (
  <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
    <svg
      role="img"
      aria-label="Shopping Bag"
      viewBox="0 0 18 22"
      xmlns="http://www.w3.org/2000/svg"
      className="brand-icon-shopping-bag"
    >
      <title>Shopping Bag</title>
      <path
        fill="fillColor"
        stroke="currentColor"
        stroke-width="context-stroke"
        d="m5.5477 5.9604h7.0388v-0.57871c0-2.6207-1.818-3.555-3.5198-3.555-1.701 0-3.519 0.93421-3.519 3.555v0.57871zm-3.9291 14.88h14.896v-14.053h-3.1097v2.5629h-0.81856v-2.5629h-7.0388v2.5629h-0.81856v-2.5629h-3.1105v14.053zm15.715 0.82673h-16.533v-15.706h3.9291v-0.57871c0-2.6613 1.7026-4.3817 4.3376-4.3817 2.6358 0 4.3384 1.7204 4.3384 4.3817v0.57871h3.9283v15.706z"
      ></path>
    </svg>
  </div>
)

const FlipBookSection: React.FC<FlipBookSectionProps> = props => {
  const { pages, container } = props.data

  const _pages = useMemo(() => {
    return pages.reduce<Array<IPageFlipbookPageWithCover | null>>((acc, page, index) => {
      // if (page?.format === 'full') {
      acc.push({
        ...page,
        position: 'full',
        pageNumber: index,
        isVisible: true,
      })
      return acc
      // }

      // acc.push({
      //   ...page,
      //   pageNumber: index,
      //   position: 'left',
      // })
      // acc.push({
      //   ...page,
      //   pageNumber: index,
      //   position: 'right',
      // })

      // return acc
    }, [])
  }, [pages])

  if (!_pages.length) return null

  return (
    <PageContainer container={container}>
      {/* <Flipbook pages={pages} /> */}
      <FlipBookSectionWrapper>
        {_pages.map(page => {
          if (!page) return null

          const haveLink = page.link && page.link.length > 0
          const LinkComponent = haveLink ? `a` : 'div'

          return (
            <LinkComponent
              href={page.link || ''}
              key={`${page.id}-${page.pageNumber}-${page.position}`}
              style={{ position: 'relative' }}
              data-position={page.position}
              data-page-number={page.pageNumber}
            >
              <ResposiveImage
                desktop={page.image}
                style={{
                  width: '100%',
                }}
              />
              {/* {haveLink && <LinkMarker />} */}
            </LinkComponent>
          )
        })}
      </FlipBookSectionWrapper>
    </PageContainer>
  )
}

export default FlipBookSection
