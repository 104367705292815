/** @jsx jsx */
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react'
import { Button, jsx } from 'theme-ui'
import Chevron from '~/components/icons/chevron'

const HeroArrowCustom = ({ position = 'left', sx = {}, ...rest }): JSX.Element => {
  return (
    <Button
      variant="noStyle"
      sx={{
        position: 'absolute',
        top: '50%',
        transform: `translateY(-50%)`,
        left: position === 'left' && 8,
        right: position === 'right' && 8,
        zIndex: 100,
        display: 'flex !important',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        padding: 0,
        width: 30,
        height: 30,
        borderRadius: '50%',
        svg: {
          width: ['15px', '20px'],
          height: ['15px', '20px'],
          fill: 'black',
        },
        ...sx,
      }}
      {...rest}
    >
      {position === 'left' ? <RiArrowLeftSLine /> : <RiArrowRightSLine />}
    </Button>
  ) as JSX.Element
}

export default HeroArrowCustom
