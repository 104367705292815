import React from 'react'
import { PageBackground, StyledPageContainer } from './styles'
import { IPageContainerProps } from './types'

export const PageContainer = ({ container, py, px, children, background }: IPageContainerProps) => {
  const { backgroundColor, ...backgroundImage } = background || {}

  return (
    <PageBackground $backgroundImage={backgroundImage} $backgroundColor={backgroundColor}>
      <StyledPageContainer
        $size={container?.size}
        $py={py}
        $px={px}
        $disabled={container?.disabled}
      >
        {children}
      </StyledPageContainer>
    </PageBackground>
  )
}
