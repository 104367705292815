import React from 'react'
import { ISlideProps } from './types'
import ResposiveImage from '~/componentsV2/ResposiveImage'
import { HeroSliderContentWrapper, HeroSliderImageWrapper, HeroSlideWrapper } from './styles'
import { PositionalContent } from '../PositionalContent'
import TextBox from '../TextBox'

interface SlideProps {
  slide: ISlideProps
}

const Slide: React.FC<SlideProps> = props => {
  const { slide } = props
  if (!slide.image.desktopImage) {
    return null
  }

  return (
    <HeroSlideWrapper>
      <HeroSliderImageWrapper>
        <ResposiveImage
          desktop={slide.image.desktopImage}
          tablet={slide.image.tabletImage}
          mobile={slide.image.mobileImage}
        />
      </HeroSliderImageWrapper>
      <HeroSliderContentWrapper>
        <PositionalContent position={slide.textPosition}>
          <TextBox {...slide.textBox} />
        </PositionalContent>
      </HeroSliderContentWrapper>
    </HeroSlideWrapper>
  )
}

export default Slide
