import styled, { css } from 'styled-components'
import { IPageImage, TContainerSize, TDevice } from './types'
import { IMedia } from '../ResposiveImage/old'

export const PageWrapper = styled.div<{ $bgColor: string }>`
  background-color: ${({ $bgColor }) => $bgColor};
`

export const PageContent = styled.div<{ $gap?: number }>`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: ${({ $gap }) => (typeof $gap === 'number' ? $gap : 5)}px;
  padding-bottom: ${({ $gap }) => (typeof $gap === 'number' ? $gap : 5)}px;
  overflow: hidden;

  --text-color: #fff;

  color: var(--text-color);

  a {
    color: var(--text-color);
    text-decoration: none;
  }

  span {
    color: var(--text-color);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 10px;
    color: var(--text-color);

    ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
      margin: 5px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    ggap: ${({ $gap }) => (typeof $gap === 'number' ? $gap : 20)}px;
    padding-bottom: 20px;
  }
`

interface IDeviceProps {
  $device?: TDevice
}

export const DeviceControl = styled.div<IDeviceProps>`
  display: contents;
  ${({ $device }) =>
    $device === 'mobile' &&
    css`
      display: none;
    `}

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    ${({ $device }) =>
      $device === 'desktop'
        ? css`
            display: none;
          `
        : css`
            display: contents;
          `}
  }
`
