import styled from 'styled-components'

export const FlipBookSectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 767px) {
    img {
      position: relative;
    }
    [data-position='left'] {
      img {
        width: 200% !important;
        height: 100% !important;
        object-fit: cover !important;
        object-position: 0% 50% !important;
        /* left: 8px !important; */
      }
    }

    [data-position='right'] {
      img {
        width: 200% !important;
        height: 100% !important;
        object-fit: cover !important;
        /* left: calc(-100% - 8px) !important; */
        left: -100% !important;
      }
    }
  }

  @media (min-width: 768px) {
    [data-position='right'] {
      display: none;
    }
  }
`
