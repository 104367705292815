import styled from 'styled-components'

export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100vw - 40px);
  margin: 0 auto;

  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex !important;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 999;

    .slick-active button {
      background: #fff;
    }

    button {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.5);
      text-indent: -9999px;
      border: none;
      padding: 0;
      margin: 0 2px;
    }
  }

  /* .slick-slider {
    &::before {
      background: linear-gradient(0deg, rgba(38, 22, 10, 0) 0%, rgb(1, 24, 43) 100%);
      width: 100%;
      height: 176px;
      position: absolute;
      left: 0;
      z-index: 1;
      content: '';

    }
  } */
`
export const InnerDot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
`

export const WrapperDots = styled.ul`
  li {
    svg {
      opacity: 0.5;
      width: 20px;
      height: 20px;
    }
    .active-dot {
      display: none;
    }

    &.slick-active {
      .active-dot {
        display: block;
      }
      .inactive-dot {
        display: none;
      }
    }
  }
`
