import React from 'react'
import { TPageTextPosition } from '../../types'
import { PositionalContentWrapper } from './styles'

interface PositionalContentProps {
  position: TPageTextPosition
  children: React.ReactNode
}

export const PositionalContent = ({ position, children }: PositionalContentProps) => {
  return (
    <PositionalContentWrapper position={position}>
      <div>{children}</div>
    </PositionalContentWrapper>
  )
}
