import React from 'react'
import { IImageGridProps } from './types'
import { ImageGridWrapper, ImageGridItem, ImageGridItemWrapper } from './styles'
import { PageContainer } from '../../components/PageContainer'
import TextBox from '../../components/TextBox'
import { ImageWithLink } from '../../components/ImageLink'

export const CardGridSection: React.FC<IImageGridProps> = ({ data }) => {
  const { columns, images, container, background } = data

  return (
    <PageContainer container={container} background={background}>
      <ImageGridWrapper $columns={columns}>
        {images.map(image => (
          <ImageGridItemWrapper key={image.image.id}>
            <ImageGridItem $reverse={image.reverse}>
              <ImageWithLink
                to={image?.link || ''}
                desktop={image.image.desktopImage}
                tablet={image.image.tabletImage}
                mobile={image.image.mobileImage}
              />
              {image?.textBox && <TextBox {...image.textBox} />}
            </ImageGridItem>
          </ImageGridItemWrapper>
        ))}
      </ImageGridWrapper>
    </PageContainer>
  )
}

export default CardGridSection
