import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { RiCircleLine, RiCircleFill, RiPauseCircleLine, RiPlayCircleLine } from '@remixicon/react'

import Slider, { CustomArrowProps } from 'react-slick'
import { graphql, useStaticQuery } from 'gatsby'
import { ISlideProps } from '../../components/HeroSlider2/types'
import HeroArrowCustom from '../../components/HeroSlider2/HeroArrow'
import { InnerDot } from '~/componentsV2/carousel/styles'
import Slide from '../../components/HeroSlider2/Slide'
import { HeroSliderWrapper } from '../../components/HeroSlider2/styles'
import AntiqueRugsProductItem from '~/components/product-item-antique-rugs'
import { TabContent, TabItem, TabNavigation, Wrapper } from './styles'
import { PageContainer } from '../../components/PageContainer'
import { IPageBackground, IPageContainer } from '../../types'
import { Button } from 'theme-ui'
import { openInquireForm } from '~/components/header/bespoke-header/inquire-form'

interface HeroSliderProps {
  data: {
    container: IPageContainer
    background: IPageBackground
  }
}

const query = graphql`
  query AllBespokeCollectionsSlider {
    allShopifyCollection(filter: { templateKey: { regex: "/^bespoke/i" } }) {
      edges {
        node {
          handle
          title
          products {
            id
            title
            handle
            images {
              originalSrc
              srcMobileSmall
            }
            mainImage(type: OVERHEAD, fallback: FRONT) {
              ...shopifyImageSrcSetFull
            }
            mainImageHorizontal: mainImage(type: HORIZONTAL, fallback: FRONT) {
              ...shopifyImageSrcSetFull
            }
            mainImageLifestyle: mainImage(type: LIFESTYLE_ADD, fallback: LIFESTYLE) {
              ...shopifyImageSrcSetFull
            }
            mainImageLifestyleOriginal: mainImage(type: LIFESTYLE, fallback: LIFESTYLE_ADD) {
              ...shopifyImageSrcSetFull
            }
            mainImageFront: mainImage(type: FRONT, fallback: FRONT) {
              ...shopifyImageSrcSetFull
            }
            mainImageRunners: mainImage(type: RUNNERS, fallback: OVERHEAD) {
              ...shopifyImageSrcSetFull
            }
            mainImageCircular: mainImage(type: CIRCULAR, fallback: OVERHEAD) {
              ...shopifyImageSrcSetFull
            }
          }
        }
      }
    }
  }
`

const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <HeroArrowCustom position="right" {...props} />
)

const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <HeroArrowCustom {...props} />
)

const Dot = ({ active }: { active: boolean }) => {
  return (
    <InnerDot>
      <RiCircleLine className={active ? 'active-dot' : 'inactive-dot'} />
    </InnerDot>
  )
}

const CollectionSlider: React.FC<HeroSliderProps> = props => {
  const { container, background } = props?.data
  const data = useStaticQuery(query)
  const [currentTab, setCurrentTab] = useState(0)
  const sliderRef = useRef<Slider>(null)
  // const haveSlides = useMemo(
  //   () => slides.length > 0 && slides.every(slide => slide.image?.desktopImage),
  //   [slides]
  // )

  const tabs = data.allShopifyCollection.edges.reduce((acc, curr) => {
    if (curr.node.products.length > 0) {
      acc[curr.node.handle] = curr.node
    }
    return acc
  }, {})

  const currentTabContent = useMemo(() => {
    return tabs[Object.keys(tabs)[currentTab]]
  }, [currentTab, tabs])

  // const _slides = useMemo(
  //   () =>
  //     Array.from({ length: 10 }, (_, index) => ({
  //       ...slides[0],
  //       id: index,
  //     })),
  //   [slides]
  // )

  // if (!haveSlides) {
  //   return null
  // }

  if (!currentTabContent) {
    return null
  }

  return (
    <PageContainer container={container} background={background}>
      <Wrapper className="slider-section">
        <TabNavigation className="tabs-wrapper">
          {Object.keys(tabs).map((tab, index) => (
            <TabItem
              key={tab}
              className="tab-item"
              data-active={currentTab === index}
              onClick={() => setCurrentTab(index)}
            >
              {tabs[tab].title}
            </TabItem>
          ))}
        </TabNavigation>
        <div className="slider-wrapper">
          <Slider
            ref={sliderRef}
            dots={true}
            infinite={true}
            speed={1000}
            // autoplay={isPlaying}
            autoplaySpeed={10000}
            slidesToShow={3}
            slidesToScroll={1}
            arrows={true}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
          >
            {currentTabContent.products.map(product => (
              <TabItem key={product.id} className="product-item">
                <TabContent>
                  <AntiqueRugsProductItem
                    product={product}
                    collectionHandle={currentTabContent.handle}
                  />
                </TabContent>
              </TabItem>
            ))}
          </Slider>
        </div>
      </Wrapper>
    </PageContainer>
  )
}

CollectionSlider.displayName = 'CollectionSlider'

export default CollectionSlider
