import styled from 'styled-components'

const maxHeight = 1080

export const HeroSliderWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: ${maxHeight}px;

  display: flex;
  aspect-ratio: 16/9;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .slick-slider {
    height: 100%;

    .slick-list {
      height: 100%;
      width: 100%;
      max-height: ${maxHeight}px;
      aspect-ratio: 16/9;
    }

    .slick-slide {
      position: relative;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex !important;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 999;
  }
`
export const ProgressBar = styled.div<{ progress: number }>`
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${props => props.progress}%;
    background: white;
    transition: width 0.1s linear;
  }
`

export const WrapperDots = styled.div`
  position: relative;
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 50px;
  width: 80%;
`

export const DotsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  gap: 30px;
`

export const DotButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: white;
  }

  &:focus-visible {
    outline: 1px solid white;
  }
`

export const DotsCountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: white;
`

export const DotCount = styled.div`
  color: #fff;
  font-family: Arial;
  font-size: 9.3px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.177px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 15px;

  &:first-child {
    padding-left: 0;
    border-right: 1px solid #fff;
  }
`

export const InnerDot = styled.div<{ active?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => (props.active ? 'white' : 'rgba(255, 255, 255, 0.5)')};
  transition: background 0.3s ease;
  cursor: pointer;
`

export const HeroSliderImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  aspect-ratio: 16/9;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const HeroSliderContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  max-height: calc(100% - 100px);
  z-index: 10;
`

export const HeroSlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 1;
  }

  .HeroSliderWrapper {
    position: relative;
    z-index: 2;
  }
`
