import React, { useMemo } from 'react'

import { BannerWrapper } from './styles'
import { BlocksContent } from '@strapi/blocks-react-renderer'
import BlockTextRender from '../../components/BlockTextRender'
import { DeviceControl } from '../../styles'
import { TDevice } from '../../types'

export interface BannerSectionProps {
  data: {
    title: BlocksContent
    device: TDevice
  }
}

const BannerSection: React.FC<BannerSectionProps> = props => {
  const { title, device } = props.data

  if (!title) {
    return null
  }

  return (
    <DeviceControl $device={device}>
      <BannerWrapper className="banner-section">
        <BlockTextRender content={title} />
      </BannerWrapper>
    </DeviceControl>
  )
}

export default BannerSection
