import styled from 'styled-components'
import { TPageTextPosition } from '../../types'

const getPositionStyles = (position: TPageTextPosition) => {
  switch (position) {
    case 'top-left':
      return 'align-items: flex-start; justify-content: flex-start;'
    case 'top-center':
      return 'align-items: flex-start; justify-content: center;'
    case 'top-right':
      return 'align-items: flex-start; justify-content: flex-end;'
    case 'center-left':
      return 'align-items: center; justify-content: flex-start;'
    case 'center-center':
      return 'align-items: center; justify-content: center;'
    case 'center-right':
      return 'align-items: center; justify-content: flex-end;'
    case 'bottom-left':
      return 'align-items: flex-end; justify-content: flex-start;'
    case 'bottom-center':
      return 'align-items: flex-end; justify-content: center;'
    case 'bottom-right':
      return 'align-items: flex-end; justify-content: flex-end;'
    default:
      return 'align-items: center; justify-content: center;'
  }
}

export const PositionalContentWrapper = styled.div<{ position: TPageTextPosition }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ position }) => getPositionStyles(position)}
`
