import React from 'react'
import TextBox from '../../components/TextBox'
import { PageContainer } from '../../components/PageContainer'
import { TextBoxProps } from '../../components/TextBox/types'
import { IPageBackground, IPageContainer, TContainerSize } from '../../types'

export interface TextSectionData {
  textBox: TextBoxProps
  container: IPageContainer
  background: IPageBackground
}

export interface TextSectionProps {
  data: TextSectionData
}

const TextSection: React.FC<TextSectionProps> = props => {
  const { textBox, container, background } = props.data

  return (
    <PageContainer container={container} py={60} background={background}>
      <TextBox {...textBox} />
    </PageContainer>
  )
}

export default TextSection
