import styled, { css } from 'styled-components'
import { TGridColumns, TGridMasonryColumns } from '../../types'
import { Link } from 'gatsby'

const gridColumns = {
  'Cols 1 / 1 / 1': '1fr 1fr 1fr',
  'Cols 1 / 1': '1fr 1fr',
  'Cols 1 / 2': '1fr 2fr',
  'Cols 2 / 1': '2fr 1fr',
}

export const ImageGridItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ImageGridItem = styled.div<{ $reverse?: boolean }>`
  height: 100%;
  position: relative;
  object-fit: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 10px 0;

  .text-box {
    width: 100%;
    margin-top: 50px;
    align-items: flex-start;

    @media (max-width: 767px) {
      justify-self: center;
      align-self: center;
      padding: 0 20px;
    }
  }

  .cta {
    align-self: center;
  }

  ${({ $reverse }) =>
    $reverse &&
    css`
      flex-direction: column-reverse;

      .cta {
        margin-bottom: 50px;
      }
    `}

  img {
    width: 100%;
    height: auto;
  }
`

export const ImageGridItemLink = styled(Link)`
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
  overflow: hidden;
`

export const ImageGridWrapper = styled.div<{ $columns: TGridColumns }>`
  margin: 0 auto;
  display: grid;
  gap: 8px;
  width: 100%;
  grid-template-columns: 100%;

  > * {
    min-width: 0;
    width: 100%;
  }

  @media (min-width: 1440px) {
    max-width: 1440px;
    padding: 70px 0;
    grid-template-columns: ${({ $columns }) => gridColumns[$columns]};
  }
`
